import { Component, render } from 'utils/jsx'
var $ = require("jquery");
window.jQuery = $;
window.$ = $;
// typical import
var Flickity = require('flickity');


// A $( document ).ready() block.
$(document).ready(function () {

  var mainTicker = new Flickity('.carousel', {
    // options, defaults listed
    cellAlign: 'left',
    freeScroll: true,
    imagesLoaded: true,
    contain: true,
    fullscreen: true,
    lazyLoad: 12,
    wrapAround: true,
    // disable previous & next buttons and dots
    prevNextButtons: false,
    pageDots: false
  });

  // Set initial position to be 0
  mainTicker.x = 0;

  // Reset the requestId for the next animation.
  mainTicker.on('dragStart', () => mainTicker.slider.style.pointerEvents = 'none');
  mainTicker.on('dragEnd', () => mainTicker.slider.style.pointerEvents = 'auto');
  // Start the marquee animation
  play();

  // Main function that 'plays' the marquee.
  function play(requestId) {
    // Set the decrement of position x
    mainTicker.x -= .35;

    // Settle position into the slider
    mainTicker.settle(mainTicker.x);

    // Set the requestId to the local variable
    requestId = window.requestAnimationFrame(play);
  }

  // Main function to cancel the animation.
  function pause() {
    if (requestId) {
      // Cancel the animation
      window.cancelAnimationFrame(requestId)

      // Reset the requestId for the next animation.
      requestId = undefined;
    }
  }

  // Pause on hover/focus
  $('.js-main-slider').on('mouseenter focusin', e => {
    pause();
  })

  // Unpause on mouse out / defocus
  $('.js-main-slider').on('mouseleave', e => {
    play();
  })

  // Initialise our components on jQuery.ready…
  // run once when page loads

  // Cursor functionality
  var ball = document.getElementById("ball");
  var cursorText = document.getElementById("cursor-text");

  var hoverAreas = document.querySelectorAll('.hover-area');

  var lastHoveredElement = null;

  function updateCursor(e) {
    var x = e.clientX;
    var y = e.clientY;

    var scrollX = window.pageXOffset || document.documentElement.scrollLeft;
    var scrollY = window.pageYOffset || document.documentElement.scrollTop;

    ball.style.left = x + scrollX + "px";
    ball.style.top = y + scrollY + "px";
    cursorText.style.left = x + scrollX + "px";
    cursorText.style.top = y + scrollY + "px";

    if (lastHoveredElement === null) {
      ball.style.display = 'block';
      ball.style.height = "10px";
      ball.style.width = "10px";
      cursorText.style.opacity = '0';
    }
  }

  function handleHoverEnter(e) {
    cursorText.innerHTML = e.target.getAttribute('data-cursor-text');
    cursorText.style.opacity = '1';
    ball.style.height = "100px";
    ball.style.width = "100px";

    lastHoveredElement = e.target;
  }

  function handleHoverLeave(e) {
    cursorText.style.opacity = '0';
    ball.style.display = 'block';

    lastHoveredElement = null;
  }

  document.addEventListener('mousemove', updateCursor);

  hoverAreas.forEach(function (elem) {
    elem.addEventListener('mouseenter', handleHoverEnter);
    elem.addEventListener('mouseleave', handleHoverLeave);
  });

  $("a[href='#top']").click(function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

});
